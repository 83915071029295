import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import Page from '../Page';
import { PageChildrenEnum } from '../../enums';
import A from '../AutostadtA';
import Checklist from './Sections/Checklist';
import Plan from './Sections/Plan';
import Luggage from './Sections/Luggage';
import PickUpPickUp from './Sections/PickUpPickUp';
import Contact from './Sections/Contact';
import QuickCheckIn from './Sections/QuickCheckIn';
import { EditorialContentProps } from '../Page/interfaces';

export default function PickUp(props: EditorialContentProps): JSX.Element {
    const [iframe, setIframe] = useState<string>('');
    const [showChevron, setShowChevron] = useState<boolean>(true);
    const introTextRef = useRef<HTMLParagraphElement>(null);
    const [introHeight, setIntroHeight] = useState('auto');

    useEffect(() => {
        if (introTextRef.current) {
            const height = introTextRef.current.offsetHeight;
            setIntroHeight(height + 300 + 'px');
        }
    }, []);

    window.addEventListener('scroll', () => {
        if (window.scrollY > 100) {
            setShowChevron(false);
        } else {
            setShowChevron(true);
        }
    });

    return (
        <Page
            child={PageChildrenEnum.PickUp}
            iframeSrc={iframe}
            closeIframe={() => setIframe('')}
            editorialContent={props.editorialContent}
        >
            <div className='pick-up'>
                <div id='intro' style={{ height: introHeight }}>
                    <div className='blue-background' />
                    <A name='blueA' fill='url(#linear-gradient-A)' firstColor='#00ABE9' secondColor='#21307D' />
                    <div className='intro-text' ref={introTextRef}>
                        <h1>IHR GLANZMOMENT ZUM GREIFEN NAH!</h1>
                        <div className='blue-line' />
                        <p>
                            Hier in der Autostadt haben wir alles vorbereitet, damit Ihre erste Begegnung mit dem
                            Neuwagen und Ihr Aufenthalt bei uns zu einem unvergesslichen Erlebnis werden.
                            <br />
                            <br />
                            Was Sie dazu wissen müssen, verraten wir Ihnen hier.
                        </p>
                    </div>
                    <svg className={`chevron${!showChevron ? ' fade' : ''}`}>
                        <use xlinkHref='#double-chevron-right' />
                    </svg>
                </div>

                <Checklist />
                <QuickCheckIn />
                <Plan />
                <Luggage />
                <PickUpPickUp />
                <Contact />
                <p className='feedback'>
                    <a href='https://autostadt.survalyzer.eu/nrrgodwpvh?l=de'>Ihre Meinung ist uns wichtig.</a>
                </p>
                <p className='year'>-- 2024 --</p>
            </div>
        </Page>
    );
}
