import React from 'react';

export default function QuickCheckIn(): JSX.Element {
    return (
        <div id='checkin'>
            <h1>SPAREN SIE ZEIT MIT UNSEREM QUICK CHECK-IN</h1>
            <p>Vorab online einchecken und mehr Zeit ins Entdecken stecken:</p>
            <p>
                Mit unserem Quick Check-in erhalten Sie Ihren QR-Code zur Anmeldung via E-Mail und verkürzen so Ihre
                Wartezeiten vor Ort.
            </p>
            <a href='https://www.autostadt.de/delegate/abholung' rel='noreferrer' target='_blank'>
                <p className='checkin-link'>
                    Jetzt online einchecken
                    <svg>
                        <use xlinkHref='#arrow-link' />
                    </svg>
                </p>
            </a>
        </div>
    );
}
