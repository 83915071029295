import React from 'react';

export default function Luggage(): JSX.Element {
    return (
        <div id='luggage'>
            <div className='dashed-line'>
                <div className='blue-circle' />
                <div className='green-dot green-dot-1' />
                <div className='green-dot green-dot-2' />
            </div>
            <svg>
                <use xlinkHref='#luggageSVG' />
            </svg>
            <h1 className='big-headline center line'>SIE MÖCHTEN IHR GEPÄCK VOR ORT ABGEBEN?</h1>
            <p>
                In der Autostadt WelcomeLounge im Eingangsbereich des Wolfsburger Bahnhofs nehmen wir Ihr Gepäck nach
                der Ankunft mit dem Zug von 08:00 Uhr bis 14:00 Uhr entgegen.
                <br />
                <br />
                Sofern Sie mit dem Auto anreisen, haben Sie die Möglichkeit, Ihr Gepäck von 08:00 Uhr bis 16:00 Uhr dem
                Autostadt-Team in der Gepäckannahme neben der Brotmanufaktur "Das Brot." auszuhändigen.
            </p>
        </div>
    );
}
