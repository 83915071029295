import React, { useContext, useState } from 'react';
import CustomLink from '../CustomLink';
import { MenuProps } from './interfaces';
import './styles.scss';
import SspProviderContext from '../../utils/SspProvider/context';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { getHighlights } from '../Expose/utils/getHighlights';
import i18next from 'i18next';

export default function Menu(props: MenuProps) {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const storyItems = contentModel.reflist('StoryItems');
    const productionAndLogistics: SSPContentVO[] = storyItems.filter(
        item => item.asModel.target.Kategorie === 'products_logistics'
    );
    const traditionAndMilestones: SSPContentVO[] = storyItems.filter(
        item => item.asModel.target.Kategorie === 'tradition_milestones'
    );
    const technologyAndInnovation: SSPContentVO[] = storyItems.filter(
        item => item.asModel.target.Kategorie === 'technology_innovations'
    );
    const sustainabilityAndSocial: SSPContentVO[] = storyItems.filter(
        item => item.asModel.target.Kategorie === 'sustainability_social'
    );
    const vehicleInAutostadt: SSPContentVO[] = storyItems.filter(
        item => item.asModel.target.Kategorie === 'vehicle_Autostadt'
    );

    const [subList, setSubList] = useState<number>(0);

    return (
        <div className={subList === 1 || subList === 2 || subList === 3 ? 'menu white-background' : 'menu'}>
            <div
                className={`menu-blue-background ${subList === 1 || subList === 2 || subList === 3 ? 'sub-list' : ''}`}
            />
            <div className='text-area'>
                <ul className='main-list'>
                    {(subList === 0 || subList === 1) && (
                        <>
                            <li onClick={() => setSubList(1)}>
                                <div className={subList === 1 ? 'main-link-active' : 'main-link'}>
                                    {subList !== 1 && (
                                        <svg className='menu-icon' style={{ fill: 'white', stroke: 'none' }}>
                                            <use xlinkHref='#car' />
                                        </svg>
                                    )}

                                    <span className={subList === 1 ? 'main-link-active bold' : 'bold'}>
                                        Mein Fahrzeug
                                    </span>

                                    {subList !== 1 && (
                                        <svg className='chevron-icon' style={{ fill: 'none', stroke: 'white' }}>
                                            <use xlinkHref='#nav-chevron' />
                                        </svg>
                                    )}
                                </div>
                            </li>
                            {subList === 1 && (
                                <li className='sub-list'>
                                    <ul>
                                        <CustomLink to='/#overview'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>{i18next.t('Overview')}</span>
                                            </li>
                                        </CustomLink>
                                        {getHighlights(contentModel).length > 0 && (
                                            <CustomLink to='/#highlights'>
                                                <li
                                                    onClick={() => {
                                                        props.setMenuOpen(false);
                                                    }}
                                                >
                                                    <span>{i18next.t('Highlights')}</span>
                                                </li>
                                            </CustomLink>
                                        )}
                                        <CustomLink to='/#gallery'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>{i18next.t('Gallery')}</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/#details'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>{i18next.t('Details')}</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/#links'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>{i18next.t('Links')}</span>
                                            </li>
                                        </CustomLink>
                                    </ul>
                                </li>
                            )}
                        </>
                    )}
                    {(subList === 0 || subList === 2) && props.editorialContent && (
                        <>
                            <li onClick={() => setSubList(2)}>
                                <div className={subList === 2 ? 'main-link-active' : 'main-link'}>
                                    {subList !== 2 && (
                                        <svg className='menu-icon'>
                                            <use xlinkHref='#story_white' />
                                        </svg>
                                    )}

                                    <span className={subList === 2 ? 'main-link-active bold' : 'bold'}>Story</span>
                                    {subList !== 2 && (
                                        <svg className='chevron-icon' style={{ fill: 'none', stroke: 'white' }}>
                                            <use xlinkHref='#nav-chevron' />
                                        </svg>
                                    )}
                                </div>
                            </li>
                            {subList === 2 && (
                                <li className='sub-list'>
                                    <ul>
                                        {productionAndLogistics.length > 0 && (
                                            <CustomLink to='/story#production-and-logistics'>
                                                <li
                                                    onClick={() => {
                                                        props.setMenuOpen(false);
                                                    }}
                                                >
                                                    <span>{i18next.t('ProductionAndLogistics')}</span>
                                                </li>
                                            </CustomLink>
                                        )}
                                        {traditionAndMilestones.length > 0 && (
                                            <CustomLink to='/story#tradition-and-milestones'>
                                                <li
                                                    onClick={() => {
                                                        props.setMenuOpen(false);
                                                    }}
                                                >
                                                    <span>{i18next.t('TraditionAndMilestones')}</span>
                                                </li>
                                            </CustomLink>
                                        )}
                                        {technologyAndInnovation.length > 0 && (
                                            <CustomLink to='/story#technology-and-innovation'>
                                                <li
                                                    onClick={() => {
                                                        props.setMenuOpen(false);
                                                    }}
                                                >
                                                    <span>{i18next.t('TechnologyAndInnovation')}</span>
                                                </li>
                                            </CustomLink>
                                        )}

                                        {sustainabilityAndSocial.length > 0 && (
                                            <CustomLink to='/story#sustainability-and-social'>
                                                <li
                                                    onClick={() => {
                                                        props.setMenuOpen(false);
                                                    }}
                                                >
                                                    <span>{i18next.t('SustainabilityAndSocial')}</span>
                                                </li>
                                            </CustomLink>
                                        )}
                                        {vehicleInAutostadt.length > 0 && (
                                            <CustomLink to='/story#vehicle-in-autostadt'>
                                                <li
                                                    onClick={() => {
                                                        props.setMenuOpen(false);
                                                    }}
                                                >
                                                    <span>{i18next.t('VehicleInAutostadt')}</span>
                                                </li>
                                            </CustomLink>
                                        )}

                                        <CustomLink to='/story#fun-and-facts'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>{i18next.t('FunAndFacts')}</span>
                                            </li>
                                        </CustomLink>
                                    </ul>
                                </li>
                            )}
                        </>
                    )}

                    {(subList === 0 || subList === 3) && (
                        <>
                            <li onClick={() => setSubList(3)}>
                                <div className={subList === 3 ? 'main-link-active' : 'main-link'}>
                                    {subList !== 3 && (
                                        <svg className='menu-icon'>
                                            <use xlinkHref='#home' />
                                        </svg>
                                    )}
                                    <span className={subList === 3 ? 'main-link-active bold' : 'bold'}>
                                        Meine Erlebnisabholung
                                    </span>
                                    {subList !== 3 && (
                                        <svg className='chevron-icon' style={{ fill: 'none', stroke: 'white' }}>
                                            <use xlinkHref='#nav-chevron' />
                                        </svg>
                                    )}
                                </div>
                            </li>
                            {subList === 3 && (
                                <li className='sub-list'>
                                    <ul>
                                        <CustomLink to='/pick-up#intro'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Willkommen</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/pick-up#checklist'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Checkliste</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/pick-up#checkin'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Online Check-in</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/pick-up#plan'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Anreise</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/pick-up#luggage'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Gepäckabgabe</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/pick-up#pick-up'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Fahrzeugabholung</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/pick-up#contact'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Kontakt</span>
                                            </li>
                                        </CustomLink>
                                    </ul>
                                </li>
                            )}
                            {subList === 0 && (
                                <>
                                    <li className='last'>
                                        <CustomLink to='/map'>
                                            <div
                                                className='main-link'
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <svg className='menu-icon'>
                                                    <use xlinkHref='#map' />
                                                </svg>
                                                <span className='bold'>Übersichtsplan </span>
                                            </div>
                                        </CustomLink>
                                    </li>
                                </>
                            )}
                        </>
                    )}
                </ul>
                {subList === 0 && (
                    <div className='further'>
                        <ul className='further-links'>
                            <li>
                                <div className='blue-marker' />
                                <span>
                                    <a rel='noreferrer' target='_blank' href='https://www.autostadt.de'>
                                        Autostadt
                                    </a>
                                </span>
                            </li>
                            <CustomLink to={'/opening'}>
                                <li>
                                    <div className='blue-marker' />
                                    <span>Öffnungszeiten</span>
                                </li>
                            </CustomLink>
                            <CustomLink to='/contact'>
                                <li
                                    onClick={() => {
                                        props.setMenuOpen(false);
                                    }}
                                >
                                    <div className='blue-marker' />
                                    <span>Kontakt</span>
                                </li>
                            </CustomLink>
                            <li>
                                <div className='blue-marker' />
                                <span>
                                    <a
                                        rel='noreferrer'
                                        target='_blank'
                                        href='https://www.autostadt.de/service-kontakt/faq'
                                    >
                                        FAQ
                                    </a>
                                </span>
                            </li>
                            <li>
                                <div className='blue-marker' />
                                <span>
                                    <a
                                        rel='noreferrer'
                                        target='_blank'
                                        href='https://www.autostadt.de/service-kontakt/newsletter'
                                    >
                                        Newsletter
                                    </a>
                                </span>
                            </li>
                            <li>
                                <div className='blue-marker' />
                                <span>
                                    <a
                                        rel='noreferrer'
                                        target='_blank'
                                        href='https://autostadt.survalyzer.eu/nrrgodwpvh?l=de'
                                    >
                                        Feedback
                                    </a>
                                </span>
                            </li>
                            <li>
                                <div className='blue-marker' />
                                <span>
                                    <a
                                        rel='noreferrer'
                                        target='_blank'
                                        href='https://www.autostadt.de/footer/rechtliches/datenschutzerklaerung'
                                    >
                                        Datenschutzerklärung
                                    </a>
                                </span>
                            </li>
                            <li>
                                <div className='blue-marker' />
                                <span>
                                    <a
                                        rel='noreferrer'
                                        target='_blank'
                                        href='https://www.autostadt.de/footer/impressum'
                                    >
                                        Impressum
                                    </a>
                                </span>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            <div className='menu-close-container'>
                <button
                    className={`close-button-menu ${subList !== 0 ? ' close-sub-list' : ''}`}
                    onClick={() => {
                        props.setMenuOpen(false);
                    }}
                >
                    <svg>
                        <use xlinkHref='#close' />
                    </svg>
                </button>
                {subList === 0 && (
                    <div className='goback-text' onClick={() => props.setMenuOpen(false)}>
                        Menü schließen
                    </div>
                )}
            </div>

            {/* Blue-Bar */}
            {/*<div className={subList !== 0 ? "blue-bar opened" : "blue-bar"}>*/}
            {/*    /!* <button className="close-button" onClick={() => setSubList(0)}> *!/*/}
            {/*</div>*/}

            {(subList === 1 || subList == 2 || subList === 3) && (
                <div className='goback-text-sublist' onClick={() => setSubList(0)}>
                    <svg>
                        <use xlinkHref='#back-arrow-link' />
                    </svg>
                    <span>Zurück</span>
                </div>
            )}
        </div>
    );
}
